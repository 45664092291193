<template>
  <div>
    <div v-if="menus.length < 8" class="menus">
      <div class="menus-item" v-for="(menu1, index1) in menus" :key="index1" @mouseleave="handleMouseLeave()"
        @click="handleMenuClick(menu1, index1)">
        <!--    <div class="menus-item"  v-for="(menu1,index1) in menus" :key="index1" @click="handleMenuClick(menu1,index1)">-->
        <span class="title" :class="{ active: active(menu1) }" :title="menu1.title">{{ menu1.title }}</span>
        <div class="submenus" :class="{
          openSubmenu: popupIndex == index1
        }" v-if="menu1.children">
          <div class="submenus-main">
            <!--          <div class="submenus-main-item" :class="{'sub-active1':active(menu2)}" v-for="(menu2,index2) in menu1.children" :key="index2" @mouseenter="handleMouseEnter(menu2,index2)" @click.stop="handleSubMenuClick(menu2,index2)">-->
            <div class="submenus-main-item" v-for="(menu2, index2) in menu1.children" :key="index2"
              @mouseenter="handleMouseEnter(menu2, index2)" @click.stop="handleSubMenuClick(menu2, index2)">
              <span :title="menu2.title">{{ menu2.title }}</span>
              <i v-if="menu2.children" class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="submenus-other" v-if="submenuChildren.length">
            <!--          <div class="submenus-other-item" :class="{'sub-active2':active(menu3)}" v-for="(menu3,index3) in submenuChildren" :key="index3" @click.stop="handleSubMenuClick(menu3,index3)">-->
            <div class="submenus-other-item" v-for="(menu3, index3) in submenuChildren" :key="index3"
              @click.stop="handleSubMenuClick(menu3, index3)">
              <span :title="menu3.title">{{ menu3.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="menus more7">
      <div class="menus-item" style="padding-left: 24px;flex: 0">
        <span class="title" @click="goto(menus[0].path)">{{ menus[0].title }}</span>
      </div>
      <div class="more7-scrolls">
        <div class="prev" :style="{
          filter: menusPageNo == 0 ? 'grayscale(100%) opacity(0.5)' : ''
        }" @click="() => { if (menusPageNo !== 0) { menusPageNo -= 1 } else { menusPageNo = 0 } }">
          <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1569_327" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="29"
              height="28">
              <rect x="0.996094" width="28" height="28" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1569_327)">
              <path
                d="M16.195 14L6.76101 4L5 5.86667L12.673 14L5 22.1333L6.76101 24L16.195 14ZM25 14L15.6918 4L13.805 5.86667L21.478 14L13.805 22.1333L15.566 24L25 14Z"
                fill="url(#paint0_linear_1569_327)" />
            </g>
            <defs>
              <linearGradient id="paint0_linear_1569_327" x1="25.7171" y1="13.9999" x2="5.35993" y2="14.5356"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#283476" />
                <stop offset="0.994792" stop-color="#283476" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="more7-list">
          <div class="menus-item" v-for="(menu1, index1) in menus.slice(1).slice(0 + menusPageNo, menusPageNo + 6)"
            :key="index1" @mouseleave="handleMouseLeave()" @click="handleMenuClick(menu1, index1)">
            <span class="title" :class="{ active: active(menu1) }" :title="menu1.title">{{ menu1.title }}</span>
            <div class="submenus" :class="{
              openSubmenu: popupIndex == index1
            }" v-if="menu1.children">
              <div class="submenus-main">
                <!--          <div class="submenus-main-item" :class="{'sub-active1':active(menu2)}" v-for="(menu2,index2) in menu1.children" :key="index2" @mouseenter="handleMouseEnter(menu2,index2)" @click.stop="handleSubMenuClick(menu2,index2)">-->
                <div class="submenus-main-item" v-for="(menu2, index2) in menu1.children" :key="index2"
                  @mouseenter="handleMouseEnter(menu2, index2)" @click.stop="handleSubMenuClick(menu2, index2)">
                  <span :title="menu2.title">{{ menu2.title }}</span>
                  <i v-if="menu2.children" class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="submenus-other" v-if="submenuChildren.length">
                <!--          <div class="submenus-other-item" :class="{'sub-active2':active(menu3)}" v-for="(menu3,index3) in submenuChildren" :key="index3" @click.stop="handleSubMenuClick(menu3,index3)">-->
                <div class="submenus-other-item" v-for="(menu3, index3) in submenuChildren" :key="index3"
                  @click.stop="handleSubMenuClick(menu3, index3)">
                  <span :title="menu3.title">{{ menu3.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next" :style="{
          filter: menusPageNo + 7 === menus.length ? 'grayscale(100%) opacity(0.5)' : ''
        }"
          @click="() => { if (menusPageNo + 7 !== menus.length) { menusPageNo += 1 } else { menusPageNo = menus.length - 7 } }">
          <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1569_327" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="29"
              height="28">
              <rect x="0.996094" width="28" height="28" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1569_327)">
              <path
                d="M16.195 14L6.76101 4L5 5.86667L12.673 14L5 22.1333L6.76101 24L16.195 14ZM25 14L15.6918 4L13.805 5.86667L21.478 14L13.805 22.1333L15.566 24L25 14Z"
                fill="url(#paint0_linear_1569_327)" />
            </g>
            <defs>
              <linearGradient id="paint0_linear_1569_327" x1="25.7171" y1="13.9999" x2="5.35993" y2="14.5356"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#283476" />
                <stop offset="0.994792" stop-color="#283476" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Menus",
  data() {
    return {
      currentMenu: '1',
      popupIndex: null,
      submenuChildren: [],
      submenuParentsPath: "/",
      menusPageNo: 0
    }
  },
  computed: {
    menus() {
      let menus = this.$store.getters.getMenus.filter(i => i.meta && !i.redirect).map(i => {
        let obj = {}
        obj.title = this.language == "CN" ? i.meta.name : i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if (i.children) {
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      let result = [...menus].filter(i => i.path !== "/search_product")
      return result
    },
    language() {
      return this.$store.getters.language
    }
  },
  watch: {
    "$route"(route) {
    },
  },
  methods: {
    goto(path) {
      this.$router.push(path)
      console.log(this.popupIndex)
      this.popupIndex = null
      console.log(this.popupIndex)
    },
    menusFilter(item) {
      let arr = item.children.filter(i => i.meta && !i.redirect).map(i => {
        let obj = {}
        obj.title = this.language == "CN" ? i.meta.name : i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if (i.children) {
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      return arr
    },
    handleMenuClick(menu, index) {
      console.log("??????", menu, index)
      if (menu.type == 10) {
        if (menu.path.slice(0, 5) == 'https') {
          window.open(menu.path)
          return;
        } else {
          window.open('https://' + menu.path)
          return;
        }

      }
      if (menu.children) {
        this.popupIndex = index
        this.submenuChildren = []
        return
      }
      this.goto(menu.path)
    },
    handleSubMenuClick(menu, index) {
      let url = menu.path
      if (menu.type == 10) {
        if (menu.path.slice(0, 5) == 'https') {
          window.open(menu.path)
          return;
        } else {
          window.open('https://' + menu.path)
          return;
        }
      }
      // if(menu.children){
      //   url = menu.children[0].path
      // }
      console.log("dddddd", url)
      this.goto(url)
    },
    handleMouseEnter(menu, index) {
      console.log(menu, index)
      if (menu.children) {
        this.submenuChildren = menu.children
        this.submenuParentsPath = menu.path
      } else {
        this.submenuChildren = []
        this.submenuParentsPath = "/"
      }
    },
    handleMouseLeave() {
      this.popupIndex = null
      this.submenuChildren = []
    },
    active(menu) {
      if (this.$route.matched.findIndex(i => i.path == menu.path) !== -1) {
        return true
      } else if (this.$route.matched.findIndex(i => i.path == "" && menu.path == "/") !== -1) {
        return true
      } else
        return false
    }
  },
}
</script>

<style lang="less" scoped>
.menus {
  display: flex;

  &-item {
    padding: 32px 24px;
    position: relative;
    cursor: pointer;
    max-width: 140px;

    .title {
      text-transform: uppercase;
      width: 100%;
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      display: inline-block;
      max-width: 140px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .submenus {
      display: none;
      position: absolute;
      top: calc(100% - 1px);
      z-index: 9999;
      left: 0;
      background: #FFFFFF;
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 8px;

      &-main,
      &-other {
        width: 182px;
        overflow: hidden;
      }

      &-main {
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 9px;
          cursor: pointer;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          border-radius: 3px 3px 3px 3px;
          padding-left: 8px;

          span {
            width: 100%;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:hover {
            background: #ECF0FE;
            color: #283476;
          }
        }
      }

      &-other {
        border-left: 1px solid #E8E9EB;
        padding-left: 8px;
        margin-left: 8px;

        &-item {
          padding: 9px;
          cursor: pointer;

          span {
            width: 100%;
            overflow: hidden;
            display: inline-block;
            word-break: break-all;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:hover {
            background: #F0F0F2;
            border-radius: 2px 3px 3px 3px;
          }
        }
      }
    }

    .openSubmenu {
      display: flex;
    }
  }

  .active {
    color: #283476;
  }

  .sub {
    &-active1 {
      background: #ECF0FE;
      color: #283476;
    }

    &-active2 {
      background: #F0F0F2;
      border-radius: 2px 3px 3px 3px;
    }
  }
}

.more7 {
  width: 700px;

  &-scrolls {
    padding: 0 24px;
    flex: 1;
    display: flex;

    .prev {
      transform: rotate(180deg);
      //padding: 2px;
    }

    .prev,
    .next {
      align-items: center;
      height: 100%;
      display: flex;
      cursor: pointer;
      //background: #283476;
    }
  }

  &-list {
    flex: 1;
    width: 0;
    //overflow: hidden;
    display: flex;
    //margin:0 24px;
  }

  .menus-item {
    padding-right: 0;
    padding-left: 0;
    width: calc(100% / 6);
    display: flex;
    justify-content: center;
  }
}
</style>
