<template>
  <div class="SearchMini" @ @wheel.prevent>
    <i class="el-icon-search icon" @click="showSearch"></i>
    <van-overlay :show="showSearchInput" @click="closeSearch" :lock-scroll="true">
      <div @click.stop class="inputArea">
        <van-search :placeholder="language=='CN'?'请输入产品名称':'Product Name'" v-model="keyword" showAction shape="round" @input="fetchSuggestions" @search="handleSearch">
          <template #action>
            <a-icon type="close" class="icon" @click="closeSearch"/>
          </template>
          <template #right-icon>
            <i class="el-icon-delete" @click="clear" ></i>
          </template>
        </van-search>
      </div>
      <div @click.stop class="selectArea" @click.stop>
        <div v-for="(item,index) in keywordsList" class="selectArea-item" @click="handleSearch(item)" :key="index">{{ item }}</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {getAction} from "@/api/manage";
import {Overlay as VanOverlay,Search as VanSearch} from "vant";

export default {
  name: "SearchMini",
  components:{
    VanOverlay,
    VanSearch
  },
  data() {
    return {
      keyword:"",
      showSearchInput:false,
      keywordsList:[]
    }
  },
  computed:{
    language(){
      return this.$store.getters.language
    }
  },
  watch:{
    showSearchInput(n){
      if(n){
        this.$emit("open")
      }
    }
  },
  methods: {
    handleSearch(keyword){
      this.keyword = keyword
      this.$router.push("/search_product?keyword="+this.keyword)
      this.closeSearch()
    },
    fetchSuggestions(keyword){
      if(!keyword){
        this.keywordsList = []
        return
      }
      getAction("/api/portal/product/list",{
        hasDisplay:1,
        name:`*${keyword?keyword:""}*`
      }).then(res=>{
        this.keywordsList = res.result.records.map(i=>i.name)
      })
      // let timeout = setTimeout(()=>{
      //   callback([{
      //     value:"123456"
      //   }])
      // },3000)
    },
    showSearch(){
      this.showSearchInput = true
    },
    closeSearch(){
      this.showSearchInput = false
      // this.clear()
    },
    clear(){
      this.keyword = ""
      this.keywordsList =  []
    }
  },
}
</script>

<style lang="less" scoped>
.SearchMini{
  margin-left: 16px;
  font-size: 0;
  .icon{
    font-size: 18px;
    color: #283476;
  }
  .selectArea{
    background: #FFFFFF;
    &-item{
      padding: 8px 16px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
    }
  }
  .inputArea{
    height: 56px;
    .van-search{
      height: 100%;
      display: inline-flex;
      align-items: center;
      width: 100%;
      padding: 9px 16px;
      .van-search__action{
        padding: 0;
        padding-left: 13px;
        display: flex;
        align-items: center;
      }
      .van-search__content{
        //padding: 0;
      }
    }
  }
}
</style>
