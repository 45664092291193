<template>
  <div class="MenusMini">
    <a-icon type="menu" class="icon" v-if="!showMenus" @click="showMenus = true" />
    <a-icon type="close" class="icon" v-else @click="showMenus = false" />
    <van-overlay :show="showMenus" z-index="3" :lock-scroll="false" @click.stop="showMenus = false">
      <div class="header">
        <div class="left">
          <logo-mini />
        </div>
        <div class="right">
          <language-mini />
          <i class="el-icon-search icon" @click.stop="$emit('openSearch')"></i>
          <a-icon type="menu" class="icon" v-if="!showMenus" @click="showMenus = true" />
          <a-icon type="close" class="icon" v-else @click="showMenus = false" />
        </div>
      </div>
      <div class="menus">
        <div class="menus-item" :class="{ openSubmenu1: popupIndex1 == index1 }" v-for="(menu1, index1) in menus" :key="index1">
          <div class="title" @click.stop="handleMenuClick(menu1, index1)" :class="{ active: active(menu1) }">
            <span class="text">{{ menu1.title }}</span>
            <i class="el-icon-arrow-right icon1" v-if="menu1.children"></i>
          </div>
          <div class="submenu chilren1" v-if="menu1.children" :style="{
            minHeight: `${(popupIndex1 == index1 ? submenu1Height(menu1) : 0) / 37.5}rem`,
            height: popupIndex1 == index1 ? popupIndex2 == null ? `${submenu1Height(menu1) / 37.5}rem` : `auto` : '0'
          }">
            <div v-for="(menu2, index2) in menu1.children" class="submenu-item" :class="{ openSubmenu2: popupIndex2 == index2 }"
              :key="index2">
              <div class="title" @click.stop="handleSubmenuClick(menu2, index2)" :class="{ active: active(menu2) }">
                <span class="text">{{ menu2.title }}</span>
                <i class="el-icon-arrow-right icon2" v-if="menu2.children"></i>
              </div>
              <div class="submenu2 chilren2" v-if="menu2.children" :style="{
                minHeight: `${(popupIndex2 == index2 ? submenu2Height(menu2) : 0) / 37.5}rem`,
                height: popupIndex2 == index2 ? `${submenu2Height(menu2) / 37.5}rem` : '0'
              }">
                <div v-for="(menu3, index3) in menu2.children" class="submenu2-item" :key="index3" @click="goto(menu3)"
                  :class="{ active: active(menu3) }">
                  {{ menu3.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Overlay as VanOverlay } from "vant";
import LanguageMini from "@/components/header/modules/LanguageMini.vue";
import LogoMini from "@/components/header/modules/LogoMini.vue";

export default {
  name: "MenusMini",
  components: {
    LogoMini,
    LanguageMini,
    VanOverlay
  },
  data() {
    return {
      currentMenu: '1',
      popupIndex1: null,
      popupIndex2: null,
      showMenus: false
    }
  },
  computed: {
    menus() {
      let menus = this.$store.getters.getMenus.filter(i => i.meta && !i.redirect).map(i => {
        let obj = {}
        obj.title = this.language == "CN" ? i.meta.name : i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if (i.children) {
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      let result = [...menus].filter(i => i.path !== "/search_product")
      return result
    },
    language() {
      return this.$store.getters.language
    },
  },
  watch: {
    "$route"(route) {
    },
    showMenus(n) {
      if (n) {
        this.$emit("open")
      }
    },
  },
  methods: {
    goto(menu) {
      if (menu.type == 10) {
        window.open(menu.path)
        this.showMenus = false
        return;
      }
      this.$router.push(menu.path)
      this.showMenus = false
    },
    menusFilter(item) {
      let arr = item.children.filter(i => i.meta && !i.redirect).map(i => {
        let obj = {}
        obj.title = this.language == "CN" ? i.meta.name : i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if (i.children) {
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      return arr
    },
    handleMenuClick(menu, index) {
      console.log("??????", menu, index, this.popupIndex1, this.popupIndex1 == index)
      if (menu.children) {
        this.popupIndex2 = null
        if (this.popupIndex1 == index) {
          this.popupIndex1 = null
        } else {
          this.popupIndex1 = index
        }
        return
      }
      this.goto(menu)
    },
    handleSubmenuClick(menu, index) {
      console.log("??????", menu, index)
      if (menu.children) {
        if (this.popupIndex2 == index) {
          this.popupIndex2 = null
        } else {
          this.popupIndex2 = index
        }
        return
      }
      this.goto(menu)
    },
    submenu2Height(menu) {
      if (!menu.children) {
        return 0
      }
      if (menu.children.length == 1) {
        return 38
      }
      if (menu.children.length > 1) {
        return (menu.children.length - 2) * 34 + 44 + 28
      }
    },
    submenu1Height(menu) {
      if (!menu.children) {
        return 0
      }
      if (menu.children.length == 1) {
        return 54
      }
      if (menu.children.length > 1) {
        return (menu.children.length - 2) * 55 + 54 + 39
      }
    },
    active(menu) {
      console.log("???", this.$route.matched.filter(i => i.path == menu.path).length == 1)
      return this.$route.matched.filter(i => i.path == menu.path).length == 1
    },
  },
}
</script>

<style lang="less" scoped>
.MenusMini {
  .icon {
    font-size: 18px;
    color: #283476;
    margin-left: 16px;
  }

  .header {
    //padding: 0 180px;
    position: absolute;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-shadow: 1px 1px 10px 1px rgba(204, 204, 204, 0.46);
    margin-bottom: 10px;
    background: #FFFFFF;

    .right {
      display: flex;
      align-items: center;
    }
  }

  .menus {
    margin-top: 56px;
    overflow: auto;
    max-height: calc(100vh - 56px);
    background: #FFFFFF;
    font-size: 16px;
    padding: 16px 16px;

    &-item {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 0;
      border-top: #D7D7D9 1px solid;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          width: 0;
          text-overflow: ellipsis;
        }

        .icon1 {
          flex-shrink: 0;
          transition: all 0.3s;
        }
      }
    }
  }

  .submenu {
    padding: 0 24px;
    transition: all 0.3s;
    overflow: hidden;

    &-item {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      padding: 16px 0;
      border-top: #D7D7D9 1px solid;

      &:first-child {
        //padding-top: 0;
        border-top: none;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon2 {
          transition: all 0.3s;
        }
      }
    }


  }

  .submenu2 {
    //padding:0 24px;
    transition: all 0.3s;
    overflow: hidden;

    &-item {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      padding: 6px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .openSubmenu1 {
    height: auto;

    .title {
      .icon1 {
        transform: rotate(-90deg);
      }
    }

    .chilren1 {
      height: auto;
    }
  }

  .openSubmenu2 {
    height: auto;

    .title {
      .icon2 {
        transform: rotate(-90deg);
      }
    }

    .chilren2 {}
  }

  .active {
    color: #283476;
  }
}

</style>
