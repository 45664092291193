<template>
  <div class="GlobalHeader">
    <div class="header">
      <logo class="logo"/>
      <menus class="menus"/>
      <search class="search"/>
      <language style="margin-left: 24px"/>
    </div>
    <div class="headerCarousel">
      <header-carousel :height="height"/>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/header/modules/Logo.vue";
import Menus from "@/components/header/modules/MenusV2.vue";
import Search from "@/components/header/modules/Search.vue";
import Language from "@/components/header/modules/Language.vue";
import HeaderCarousel from "@/components/header/modules/HeaderCarousel.vue";

export default {
  name: "GlobalHeader",
  components: {HeaderCarousel, Language, Search, Menus, Logo},
  data() {
    return {
    }
  },
  computed:{
    height(){
      console.log("routeAAA",this.$route)
      // let home =window.screen.width /1920*800+"px"
      let home =800+"px"
      // let others = window.screen.width /1920*600+"px"
      let others = 600+"px"
      return this.$route.meta.type==1&&this.$route.path!=='/search_product'?home:others
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.GlobalHeader{
  .header{
    //padding: 0 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:1px 1px 10px 1px rgba(204, 204, 204, 0.46);
    //width: 100vw;
    .logo{
      flex-shrink: 1;
    }
    .menus{
      margin-left: 35px;
      //flex: 1;
      flex-shrink: 0;
      //width: 805px;
    }
    .search{
      flex-shrink: 1;
      width: 150px;
    }
  }
}
@media only screen
and (max-device-width: 1200px){

}
</style>
