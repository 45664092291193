import { render, staticRenderFns } from "./LeaveMessageMiniModal.vue?vue&type=template&id=6b0b3723&scoped=true&"
import script from "./LeaveMessageMiniModal.vue?vue&type=script&lang=js&"
export * from "./LeaveMessageMiniModal.vue?vue&type=script&lang=js&"
import style0 from "./LeaveMessageMiniModal.vue?vue&type=style&index=0&id=6b0b3723&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b0b3723",
  null
  
)

export default component.exports