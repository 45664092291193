<template>
  <el-dialog :visible.sync="visible">
    <template #title>
      <div class="top">
        <div class="title">
          {{ language == "CN" ? "在线留言" : "leave a message" }}
        </div>
        <div class="tips">
          {{
            language == "CN" ? "请您填写如下信息，我们收到信息后，会尽快与您取得联系，感谢您的留言！" : "Please fill in the following information. Once we receive the information, we will contact you as soon as possible. Thank you for your message!"
          }}
        </div>
      </div>
    </template>
    <el-form :rules="rules" :model="model" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="name">
            <el-input :placeholder="language=='CN'?'*您的姓名':'*name'" v-model="model.name"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="phone">
            <el-input :placeholder="language=='CN'?'*联系方式':'*phone number'" v-model="model.phone"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="email">
            <el-input :placeholder="language=='CN'?'邮箱地址':'E-mail'" v-model="model.email"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="company">
            <el-input :placeholder="language=='CN'?'公司名称':'organization name'" v-model="model.company"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="demand">
        <el-input type="textarea" :rows="5" :placeholder="language=='CN'?'*您想说的内容':'*message'"
                  v-model="model.demand"/>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <div class="btn" @click="submit">
        {{ language == "CN" ? "提交" : "SUBMIT" }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {postAction} from "@/api/manage";
import {Modal} from "ant-design-vue";
import {Debounce, Throttle} from "@/utils/utils"

export default {
  name: "LeaveMessageMiniModal",
  data() {

    const checkName = (rule, value, callback) => {
      if (!value) {
        callback(this.language == "CN" ? "请留下您的名字" : "Please leave your address")
      }
      callback()
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(this.language == "CN" ? "请留下您的联系方式" : "Please leave your phone")
      }
      callback()
    }
    const checkDemand = (rule, value, callback) => {
      if (!value) {
        callback(this.language == "CN" ? "请留下您想说的话" : "Please leave your demand")
      }
      callback()
    }
    return {
      visible: false,
      rules: {
        name: [
          {validator: checkName}
        ],
        phone: [
          {validator: checkPhone}
        ],
        demand: [
          {validator: checkDemand}
        ]
      },
      model: {
        name:"",
        phone:"",
        email:"",
        company:"",
        demand:"",
      },
    }
  },

  computed: {
    language() {
      return this.$store.getters.language
    }
  },
  methods: {
    show() {
      this.visible = true
      this.$refs.form.resetFields()
    },
    submit: Throttle(function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.model)
          postAction("/api/portal/common/onlineMessage", params).then(res => {
            if (res.success) {
              // this.$message.success(res.message)
              Modal.info({
                title: this.language == "CN" ? "提交成功" : "SUBMIT SUCCESS",
                content: this.language == "CN" ? "感谢您的留言，期待我们将来的合作" : "Thank you for your message. Looking forward to our future cooperation",
                zIndex: 9999,
                onOk: () => {
                  this.visible = false
                }
              })
            }else {
              this.$message.warning(res.message)
            }

          })
        }
      })
    })
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 280px;
  margin-top: 40px !important;

  .el-dialog__header {
    padding: 16px;
    padding-bottom: 0;
  }

  .el-dialog__body {
    padding-top: 12px;
    padding: 16px;
  }
}

.el-form {
  &-item {
    margin-bottom: 20px;
  }
}

.top {
  text-align: center;

  .title {
    font-family: 'Source Han Sans CN';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .tips {
    margin-top: 8px;
    font-family: 'Source Han Sans CN';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #979899;
  }
}

.btn {
  margin-top: 12px;
  background: #283476;
  color: #FFFFFF;
  display: inline-block;
  //padding: 16px 160px;
  padding: 6px 54px;
  cursor: pointer;
  font-family: 'Source Han Sans CN';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}
/deep/ .el-textarea__inner,/deep/ .el-input__inner{
  background: #F7F7F9;
}
</style>
